.dashboard {
    color: #151e26;
    background-color: #fff;
    overflow: hidden;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    min-height: 82vh;
    width: 100%;
}