@keyframes swipeOutRight {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
    
}

.fade-out-animation {
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.notification-item {
    position: relative;
    overflow: hidden; 
}

.swipe-out-right {
    animation: swipeOutRight 0.5s forwards;
}

.notification-list {
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.notification-list::-webkit-scrollbar {
    width: 3px;
}

.notification-list::-webkit-scrollbar-thumb {
    background: #eb1f36;
}

.notification-list::-webkit-scrollbar-track {
    background: #282560;
}

.read-btn {
    background-color: #282560;
    color: white;
    padding: 0.6rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.8rem;
    border: none;
}

.read-btn:hover {
    cursor: pointer;
    box-shadow: 1px 1px 5px 1px #b3b3b3;
}