.flex {
    display: flex;
}
.justify-end {
    justify-content: flex-end;
}

@media (min-width: 600px) {
    .flex {
        flex-direction: row;
    }
}

.grid {
    display: grid;
    gap : var(--space);
}

// grid-two
// |------2fr------|---1fr---|
.grid-two { grid-template-columns: 2fr 1fr; }
@media screen and (max-width : 900px){
    .grid-two {
        grid-template-columns: 1fr;
    }
}

