.dashboard-widget {
    padding: 3px;
    background-color: #f3f4f7;
    border-radius: 10px;
    color: var(--text-color);

    dl {
        dt {
            border-bottom-color: var(--bg-color);
        }
    }
}