.activity-badge {
    color: var(--primary-color);
    background-color: var(--black);
    align-items: center;
    display: inline-flex;
    padding: var(--paddingBadge);
    border-radius: var(--borderRadius, 3px);
    font-weight: var(--fontBold);
}

.activity-Pending {
    color: #FF0000;
    font-weight: var(--fontBold);
}

.activity-Hold {
    color: #e5ff00;
    font-weight: var(--fontBold);
}


.activity-Approved {
    color: #7cd121;
    font-weight: var(--fontBold);
}

.activity-Submited {
    color: var(--primary-color);
    font-weight: var(--fontBold);
}

