.link-button {
  padding: var(--paddingBox);
  display: inline-flex;
  align-items: center;
  color: var(--text-color);
  font-size: var(--fontSizeSmall);
  border: 1px solid #747476;
  background-color: transparent;
  border-radius: var(--borderRadius);
}
