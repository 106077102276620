#report {
  background-color: white;
  height: 600px;
  color: black;
  font-size: 13px;
  overflow: scroll;
  border-radius: var(--borderRadius);
  box-shadow: 0px 4px 10px rgba(0,0,0,.2);
  h1,h2,h3,h4,h5,h6 , dt{
    color: black;
  }
  dd {
      font-family: 'Courier New', Courier, monospace;
  }
}
