div.Breadcrumb {
  display: flex;
  align-self: center;
  background-color: transparent;

  .Arrow {
    color: var(--primary-color);
  }

  .Slash {
    color: var(--text-color);
  }

  > * {
    margin: var(--margin);
  }
}
