form {
    label {
        display: grid;
        align-items: center;
        grid-template-columns: 220px 1fr;
    }

    label,
    input:not([type="checkbox"]),
    select,
    button {
        margin: var(--margin) auto;
        width: 100%;
    }
}

form legend {
    color: var(--text-color);
}

form.crud fieldset legend {
    border-bottom: var(--borderWidth) solid var(--black);
    width: 100%;
    padding-bottom: var(--space-sm);
}

@media only screen and (max-width: 700px) {
    form {
        label {
            grid-template-columns: 1fr;
        }
    }
}
