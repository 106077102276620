.collapsed .sidebar {
  a span {
    color: transparent;
    display: none;
  }

  a svg:last-child {
    color: transparent;
  }

  a.sublink {
    padding-left: var(--space-sm);
  }

  button {
    display: inline;
    width: min-content;
    padding: var(--space-sm);

    svg {
      margin: 0;
      padding: 0;
    }

    span {
      display: none;
    }
  }

}

aside.sidebar {
  overflow-y: auto;
  height: 100vh;
  position: sticky;
  top: 0;
  font-size: var(--fontSizeSsidebar);
  background-color: #f3f4f7;
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  row-gap: calc(var(--padding) / 2);

  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgb(57, 54, 54);
    border-left: var(--borderWidth) solid transparent;
    padding: 5px 5px;

    svg:last-child {
      margin-left: auto;
      width: 16px;
      height: 16px;
      transition: transform .1s;
      color: rgb(139, 139, 139);

    }

    &.active {
      color: #ed1432;
      background-color: rgb(231, 229, 229);
      border-radius: 5px;
      padding: 4px;
    }
  }

  a.sublink {
    padding-left: 1.5rem;
    font-size: var(--fontSizeSsidebar);
  }
}

/* Adjust the width of the vertical scrollbar */
aside.sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Customize the appearance of the scrollbar track */
aside.sidebar::-webkit-scrollbar-track {
  background: #f3f4f7;
  /* Change to match your sidebar background color */
}

/* Customize the appearance of the scrollbar thumb */
aside.sidebar::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  /* Change to match your desired scrollbar thumb color */
}

/* Optionally, you can customize other scrollbar states like hover and active */
aside.sidebar::-webkit-scrollbar-thumb:hover {
  background: #6d6d6d;
  /* Change to match your desired scrollbar thumb hover color */
}