
.document-preview {
    margin-top: 40px;
    width: 80%;
    margin: 0 auto;
    background-color: white;
    padding: 3% 4%;
    border-radius: 5px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    * {
        color: black;
    }
}
