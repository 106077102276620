.activity-badge {
    color: var(--primary-color);
    background-color: var(--black);
    align-items: center;
    display: inline-flex;
    padding: var(--paddingBadge);
    border-radius: var(--borderRadius, 3px);
    font-weight: var(--fontBold);
}

.approval-pending {
    color: #FF0000;
    font-weight: var(--fontBold);
}

.approval-hold {
    color: #FF0000;
    font-weight: var(--fontBold);
}

.approval-approved {
    color: #18A558;
    font-weight: var(--fontBold);
}

.approval-submit {
    color: var(--primary-color);
    font-weight: var(--fontBold);
}

