.modaldialog-backdrop {
    background-color: white;
    width: 100%;
    height: 100%;
    margin: auto;
    color: red;
  }
  .dflex{
    display: flex;
    justify-content: center;
    align-items: center;
  }
