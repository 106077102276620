nav.top-header {
    position: sticky !important;
    top: 0 !important;
    width: 100%;
    padding: var(--space-sm);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: var(--space);
    z-index: 12;
    box-shadow: 1px 1px 5px #9f9f9f;

    h3 {
        margin-right: auto;
    }

    >a.logo {
        img {
            margin: 0 var(--space);
            height: 60px;
        }

        margin-right: auto;
    }
}